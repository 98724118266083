import React, { FC, useState } from 'react';
// import sgMail, { MailDataRequired } from '@sendgrid/mail';
import { motion, Variants } from 'framer-motion';

import Button from 'components/atoms/Button';

import { ContactFormProps } from './models.d';

const ContactForm: FC<ContactFormProps> = ({
  emailPlaceholder,
  messagePlaceholder,
  namePlaceholder,
  sendMessageLabel,
}) => {
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [message, setMessage] = useState<string>('');

  const formVariant: Variants = {
    hidden: {
      opacity: 0,
      y: 100,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        delay: 1,
        duration: 0.3,
      },
    },
  };

  // sgMail.setApiKey('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');

  const sendMail = (e: React.FormEvent) => {
    e.preventDefault();

    // const msg: MailDataRequired = {
    //   to: 'tom.brazkiewicz@gmail.com',
    //   from: email,
    //   subject: `Wiadomość z formularza od: ${name}`,
    //   text: message,
    // };

    // sgMail
    //   .send(msg)
    //   .then(() => console.log('wysłano'))
    //   .catch((err) => console.log(err));
  };

  return (
    <motion.form
      className="contact-form"
      variants={formVariant}
      initial="hidden"
      animate="visible"
      onSubmit={(e) => sendMail(e)}
    >
      <input
        type="text"
        placeholder={namePlaceholder}
        className="contact-form__input"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <input
        type="email"
        placeholder={emailPlaceholder}
        className="contact-form__input"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <textarea
        placeholder={messagePlaceholder}
        className="contact-form__textarea"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
      />
      <Button
        isSubmit
        customClass="contact-form__button"
        variant="secondary-outline"
        buttonLabel={sendMessageLabel}
      />
    </motion.form>
  );
};

export default ContactForm;
