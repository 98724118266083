import React from 'react';
import { graphql } from 'gatsby';
import { motion, Variants } from 'framer-motion';

import Container from 'components/organisms/Container';
import Banner from 'components/molecules/Banner';
import ContactForm from 'components/molecules/ContactForm';
import IntroSection from 'components/atoms/IntroSection';
import Seo from 'components/atoms/Seo';

const ContactUs = ({
  data: {
    contentfulContactUs: {
      contactUsBanner: { bannerSlide },
      formNamePlaceholder,
      formEmailPlaceholder,
      formMessagePlaceholder,
      contactPhoneNumber,
      contactEmail,
      contactDescription,
      companyName,
      emailLabel,
      phoneLabel,
      sendMessageLabel,
    },
  },
}) => {
  const contactVariant: Variants = {
    hidden: {
      opacity: 0,
      y: 100,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        delay: 0.7,
        duration: 0.3,
      },
    },
  };

  return (
    <div className="contact">
      <Seo title="Kontakt" />
      <Banner slides={bannerSlide} />
      <Container>
        <IntroSection text={contactDescription} justify="center" />
        <motion.div
          className="contact__content"
          variants={contactVariant}
          initial="hidden"
          animate="visible"
        >
          <div className="contact__company-name">{companyName}</div>
          <div className="contact__data-wrapper">
            <p className="contact__label">{phoneLabel}</p>
            <a href={`tel:${contactPhoneNumber}`} className="contact__data">
              {contactPhoneNumber}
            </a>
          </div>
          <div className="contact__data-wrapper">
            <p className="contact__label">{emailLabel}</p>
            <a href={`mailto:${contactEmail}`} className="contact__data">
              {contactEmail}
            </a>
          </div>
        </motion.div>
        <ContactForm
          namePlaceholder={formNamePlaceholder}
          emailPlaceholder={formEmailPlaceholder}
          messagePlaceholder={formMessagePlaceholder}
          sendMessageLabel={sendMessageLabel}
        />
      </Container>
    </div>
  );
};

export const query = graphql`
  {
    contentfulContactUs {
      contactUsBanner {
        bannerSlide {
          title
          image {
            altText
            image {
              gatsbyImageData(formats: WEBP)
            }
          }
        }
      }
      companyName
      formNamePlaceholder
      formEmailPlaceholder
      formMessagePlaceholder
      contactPhoneNumber
      contactHeading
      contactEmail
      contactDescription
      emailLabel
      phoneLabel
      sendMessageLabel
    }
  }
`;

export default ContactUs;
